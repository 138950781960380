$turquoise3268C: #00AB8E; // rgba(0, 171, 142, 1);


// PSD COLORS
$white: #ffffff;
$almost-white: #f5f5f5;
$black: #212721;
$blue-sea: #c8e5ff;
$grey-range-selector: #f9f9f9;
$grey-green-percent: #f4faff;
$grey-light: #f4f4f4;
$grey-navbar: #393939;
$grey-checkbox: #d6d6d6;
$green-asset-list-check: #57e125;
$light-grey: #f9f9f9;


$grey-country: #343434;
$grey-nav-icon: #555555;

$grey-footer-text: #848383;

$flexidao-turquoise: $turquoise3268C;

$green-one: #add47e;
$green-two: #85c486;
$green-two-and-a-half: #93d2a1;
$green-three: #8dc63f;
$green-four: #c2db7a;
$green-five: #78c159;

$green-chart: rgba(#a2d369, 0.8);

$blue-one: #0e83fc;
$blue-two: #1c5793;

$purple: #5a3f9c;


// By USAGE
$text-color: $black;
$assets-line: $grey-light;

$primary: #00ab8e;
$secondary: #f89c22;
$light: #f8f9fa;
$dark: #343a40;

// Used only to calculate the contrast
$hydro-color: #0D91DD;
$wind-color: #00AB8E;
$solar-color: #F9B04E;
$unknown-color: #A6A9A6;
$hydrogen-color: #7D43F9;
$nuclear-color: #DDBC0D;
$biomass-color: #766056;
$custom01-color: #99DDD2;

:root {
  --primary: #{$primary};
  --secondary: #{$secondary};

  --transparent: rgba(0, 0, 0, 0);

  --background: #FFFFFF;
  --text: #000000;

  --certificate-hover-bg: #FFFFFF;
  --consumption-chart-tooltip-bg: #FFFFFF;

  --hydro: var(--blue400);
  --wind: var(--green400);
  --solar: var(--orange500);
  --biomass: var(--brown400);
  --unknown: var(--grey700);
  --custom01: var(--green700);
  --hydrogen: var(--purple400);
  --nuclear: var(--yellow400);

  --hydro-contrast: #{color-yiq($hydro-color)};
  --wind-contrast: #{color-yiq($wind-color)};
  --solar-contrast: #{color-yiq($solar-color)};
  --biomass-contrast: #{color-yiq($biomass-color)};
  --unknown-contrast: #{color-yiq($unknown-color)};
  --custom01-contrast: #{color-yiq($custom01-color)};
  --hydrogen-contrast: #{color-yiq($hydrogen-color)};
  --nuclear-contrast: #{color-yiq($nuclear-color)};

  --white: #FFFFFF;
  --black: #000000;

  --elm-red: #CC0000;
  --elm-blue: #3465A4;
  --elm-green: #73D216;
  --elm-grey: #D3D7CF;
  --elm-charcoal: #555753;
  --elm-orange: #f57900;
  --elm-dark-orange: #ce5c00;

  --theme-producer: #add47e;
  --theme-consumer: #5f41a7;
  --theme-count: #0e83fc;
  --theme-count-transparent: #0e83fc80;
  --theme-red: #d30000;
  --theme-pink: #f6cccc;
  --theme-not-realtime: #{$secondary};

  --h20-rfnbo: #21cdb070;
  --h20-rfnbo-border: #00ab8e;
  --h20-not-rfnbo: #dfdfdf;
  --h20-hourly-production: #0081cb;


  --renewable-steam: #21cdb070;
  --renewable-steam-border: #00ab8e;
  --not-renewable-steam: #dfdfdf;
  --thermostorage-loading: #99C0D9;
  --thermostorage-unloading: #006596;
  --steam-production: #006596;

  --yearly-gradient-start: #646864;
  --yearly-gradient-middle: #FFFFFF;
  --yearly-gradient-end: #00ab8e;
}

$acciona-primary: #ee3b33;
$acciona-secondary: #FFFFFF;
$acciona-hydro-color: #4267c0;
$acciona-wind-color: #abc706;
$acciona-solar-color: #f99e24;
$acciona-biomass-color: #e74393;
$acciona-unknown-color: #dfe6e9;
$acciona-custom01-color: #ee3b33;
$acciona-hydrogen-color: #42DCA3;
$acciona-nuclear-color: #14CAC7;


[ci=acciona] {
  &:root {
    & body {
      background: #000;
      color: #fff;

      & .btn-primary {
        color: #000;
        background-color: #{$acciona-primary};
        border-color: #{$acciona-primary};
      }

      & .progress-bar {
        background-color: #{$acciona-primary};
      }

      & a {
        color: #fff;

        &.organism--top-bar--item:hover,
        &.atom--tab-bar--item:hover,
        &.molecule--menu--entry:hover,
        &.molecule--context-menu--main-entry:hover,
        &.molecule--menu--main-entry:hover,
        &.template--certificate-dashboard--filter-stage--item:hover,
        &.template--certificate-dashboard--filter-stage--item.active,
        &.atom--url-button {
          color: #{$acciona-primary};
        }

        &.molecule--context-menu--main-entry:hover,
        &.molecule--menu--main-entry:hover,
        &.atom--url-button:hover {
          background: #6c6c6c;
        }

        &.atom--url-button:hover {
          color: #fff;
        }
      }

      & .molecule--markdown-block a {
        color: #{$acciona-primary};
      }

      & .molecule--anonymous-asset-list--asset a {
        color: #fff;
        text-decoration: underline;
      }
    }

    --white: #fff;
    --black: #000;

    --primary: #{$acciona-primary};
    --secondary: #{$acciona-secondary};
    --red: #{$acciona-primary};

    --grey: #454545;
    --yellow: #f8c212;
    --orange: #fa6824;
    --light-blue: #01aed9;
    --green: #48773d;
    --garnet: #a31d44;
    --border-grey: #6c6c6c;
    --dark-bg: #2B2B2F;
    --dark-greeny-grey: #232a23;
    --canvas: var(--black);
    --menu-trigger-text: var(--text);
    --menu-trigger-bg: var(--dark-greeny-grey);
    --button-text: var(--white);
    --text: var(--white);
    --background: var(--dark-bg);
    --humanized-avoidance-text: var(--white);
    --token-id-text: var(--white);
    --border: var(--border-grey);
    --multiselect-border: var(--border-grey);
    --multiselect-background: var(--dark-bg);
    --multiselect-with-selection: var(--primary);
    --scrollbar-bg: var(--dark-bg);
    --scrollbar-thumb: var(--primary);
    --blockchain-event-background: var(--border-grey);
    --avoidance-bg: var(--border-grey);

    --hydro: #{$acciona-hydro-color};
    --hydro-contrast: #{color-yiq($acciona-hydro-color)};
    --hydro-otherDay: #24438f;
    --hydro-assignedToOthers: #6b86c7;
    --hydro-rest: #e4e8f1;

    --wind: #{$acciona-wind-color};
    --wind-contrast: #{color-yiq($acciona-wind-color)};
    --wind-otherDay: #7f8f24;
    --wind-assignedToOthers: #bac76b;
    --wind-rest: #eff1e4;

    --solar: #{$acciona-solar-color};
    --solar-contrast: #{color-yiq($acciona-solar-color)};
    --solar-otherDay: #8f6124;
    --solar-assignedToOthers: #c7a06b;
    --solar-rest: #f1ebe4;

    --biomass: #{$acciona-biomass-color};
    --biomass-contrast: #{color-yiq($acciona-biomass-color)};
    --biomass-otherDay: #8f2824;
    --biomass-assignedToOthers: #c76f6b;
    --biomass-rest: #f1e5e4;

    --unknown: #{$acciona-unknown-color};
    --unknown-contrast: #{color-yiq($acciona-unknown-color)};

    --custom01: #{$acciona-custom01-color};
    --custom01-contrast: #{color-yiq($acciona-custom01-color)};
    --custom01-otherDay: #8f2824;
    --custom01-assignedToOthers: #c76f6b;
    --custom01-rest: #f1e5e4;

    --hydrogen: #{$acciona-hydrogen-color};
    --hydrogen-contrast: #{color-yiq($acciona-hydrogen-color)};

    --nuclear: #{$acciona-nuclear-color};
    --nuclear-contrast: #{color-yiq($acciona-nuclear-color)};

    --product: var(--red);
    --product-otherDay: #8f2824;
    --product-assignedToOthers: #c76f6b;
    --product-rest: #f1e5e4;

    --theme-not-realtime: #C06C68;
  }
}

// settings-ui colors import

$grey-land: #202020;
$grey-bg: #e9e9e9;
$grey-filters: #232425;
$grey-tabs: #2a2a2a;
$grey-sea: #3c3c3c;
$grey-cart-hack: #c4c4c4;

$red-acciona: #ee3b33;
$red-negative-variation: #ee3b33;
$green-positive-variation: #00AB8E;


// Elm Fancy DateRangePicker
$efdp-themes: (
        light: (
                color-header-text: $black,
                color-primary: $turquoise3268C,
                color-dark-onhover: $turquoise3268C,
                color-secondary: $white,
                color-white: $white,
                color-header-bg: $white,
                color-border: $white,
                color-text: $black,
                color-round-btn-border: rgb(236, 236, 236),
                color-input-label: rgb(0, 0, 0),
                color-box-shadow: rgb(0, 0, 0),
                color-bg-light-gray: $white,
                color-text-primary: $turquoise3268C,
                color-dp-hover: rgba($white, 0.8),
                color-bg-primary: $turquoise3268C,
                color-text-primary-selected: $white,
                color-border-primary: $turquoise3268C,
                color-text-primary-white: $turquoise3268C,
                color-text-black-white: rgb(98, 98, 98)
        ),
) !default;

// CO2 colors
$co2-bg: #dfe6e9;
$co2-number: #ffffff;
$co2-icon: #42dca3;

// colours from FlexiDAO palette https://flexidao.atlassian.net/wiki/spaces/PD/pages/2358476828/Color+palette

:root {
  // palettes
  --green900: #E6F7F4;
  --green800: #CCEEE8;
  --green700: #99DDD2;
  --green600: #66CDBB;
  --green500: #33BCA5;
  --green400: #00AB8E;
  --green300: #058E77;
  --green200: #09725E;
  --green100: #0E5447;
  --green000: #12382E;

  --orange900: #FFF6E9;
  --orange800: #FEEBD3;
  --orange700: #FCD7A7;
  --orange600: #FBC47A;
  --orange500: #F9B04E;
  --orange400: #F89C22;
  --orange300: #CB8220;
  --orange200: #9E691D;
  --orange100: #714E1C;
  --orange000: #443519;

  --red900: #FBEEE9;
  --red800: #F6DDD3;
  --red700: #EDBBA7;
  --red600: #E4997B;
  --red500: #DA774F;
  --red400: #D15523;
  --red300: #AC4921;
  --red200: #873E1E;
  --red100: #62321C;
  --red000: #3C2719;

  --blue900: #E7F5FC;
  --blue800: #CFE9F8;
  --blue700: #9ED3F1;
  --blue600: #6EBDEB;
  --blue500: #3DA7E4;
  --blue400: #0D91DD;
  --blue300: #0F79B6;
  --blue200: #11628E;
  --blue100: #134A66;
  --blue000: #15333E;

  --grey900: #F3F4F3;
  --grey800: #E9E9E9;
  --grey700: #D3D4D3;
  --grey600: #BCBEBC;
  --grey500: #A6A9A6;
  --grey400: #909390;
  --grey300: #7A7D7A;
  --grey200: #646864;
  --grey100: #4D524D;
  --grey000: #333933;

  --purple900: #F0E9FF;
  --purple800: #D9C7FD;
  --purple700: #C2A7FC;
  --purple600: #AB86FB;
  --purple500: #9565FB;
  --purple400: #7D43F9;
  --purple300: #6622F8;
  --purple200: #5820D0;
  --purple100: #4619A6;
  --purple000: #34147A;

  --yellow900: #FAF5DB;
  --yellow800: #F5EBB7;
  --yellow700: #EFE092;
  --yellow600: #EBD76E;
  --yellow500: #E2C840;
  --yellow400: #DDBC0D;
  --yellow300: #C2A50C;
  --yellow200: #A9900C;
  --yellow100: #7D6B09;
  --yellow000: #5F5108;

  --brown900: #EAE6E5;
  --brown800: #D5CECA;
  --brown700: #BAACA6;
  --brown600: #A7938A;
  --brown500: #8B766D;
  --brown400: #766056;
  --brown300: #634D44;
  --brown200: #524038;
  --brown100: #3F312B;
  --brown000: #251D19;

  --flexidaoGreen: var(--green500);
  --flexidaoOrange: var(--orange500);
  --flexidaoRed: var(--red500);
  --flexidaoBlue: var(--blue500);
  --flexidaoWhite: #FAFBFA;
  --flexidaoBlack: #171B17;
  --pureWhite: #FFFFFF;
  --pureBlack: #000000;

  // graphics
  // CFE
  --graphic-contracted_cfe: var(--green400);
  --graphic-grid_mix_cfe: var(--green600);
  --graphic-grid_mix_non_cfe: var(--grey700);
  --graphic-excess_cfe: var(--orange400);
  --graphic-consumption: var(--blue400);
  //Co2
  --graphic-location_based_co2: var(--blue400);
  --graphic-market_based_co2: var(--orange400);
}

:root {
  --ops-from-grid : var(--red400);
  --ops-to-grid : var(--orange400);
  --ops-power-generated : var(--green400);
  --ops-from-battery : var(--blue400);
  --ops-to-battery : var(--grey400);
}

[ci="ree"] {
  &:root {
    --ops-from-grid :#00C87D;
    --ops-to-grid :#9F3;
    --ops-power-generated :#E48500;
    --ops-from-battery :#9BC1DB;
    --ops-to-battery :#069;
  }
}